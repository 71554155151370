import { Helmet } from "react-helmet";
import Layout from "./layout";
import React, { useContext } from "react";
import SeoHeaders from "../components/seo/seo-headers";
import pageBlock2columntextwithsidetitle from "../components/page-blocks/page-block-2columntextwithsidetitle";
import pageBlock2columntextwithtitle from "../components/page-blocks/page-block-2columntextwithtitle";
import pageBlock3columnimagetext from "../components/page-blocks/page-block-3columnimagetext";
import pageBlockAdvantages from "../components/page-blocks/pageBlockAdvantages";
import pageBlockBrochure from "../components/page-blocks/page-block-brochure";
import pageBlockBrochureForm from "../components/page-blocks/page-block-brochure-form";
import pageBlockContact from "../components/page-blocks/page-block-contact";
import pageBlockCruiseInspirations from "../components/page-blocks/page-block-cruiseinspirations/pageBlockCruiseInspirations";
import pageBlockCruiseTypes from "../components/page-blocks/page-block-cruisetypes/pageBlockCruiseTypes";
import pageBlockDynamic from "../components/page-blocks/page-block-dynamic/pageBlockDynamic";
import pageBlockHeader from "../components/page-blocks/page-block-header";
import pageBlockImagesidetext from "../components/page-blocks/page-block-imagesidetext.jsx";
import pageBlockImageswithhovercaption from "../components/page-blocks/page-block-imageswithhovercaption";
import pageBlockLargeimagesidetext from "../components/page-blocks/page-block-largeimagesidetext";
import pageBlockNewsletter from "../components/page-blocks/page-block-newsletter";
import pageBlockParagraphwithsidebar from "../components/page-blocks/page-block-paragraphwithsidebar";
import pageBlockQuote from "../components/page-blocks/page-block-quote";
import pageBlockTextgallery from "../components/page-blocks/page-block-textgallery";
import pageBlockTextpageblockhighlight from "../components/page-blocks/page-block-textpageblockhighlight";
import { websiteName } from "../settings";
import GlobalContext from "../contexts/global-provider";

const Page = ({
  pageContext: { page, sailingAreas, navigation, privacy, cruiseTypes, cmsProducts, erpSailingAreas },
  location,
}) => {

  const {setNavigation, setPrivacy, theme} = useContext(GlobalContext);
  setNavigation(navigation);
  setPrivacy(privacy);

  // Fast escape
  if (!page?.children?.length) {
    return <></>;
  }

  const seo = page.content.seo;
  const pageTitle = seo && seo.title ? seo.title : page.content.general.title;

  // Parse components
  var renderComponents = parseComponents(page.children);

  // Layout
  return (
    <Layout
      isTiny={Boolean(page.content.layout.isTiny)}
      headerImage={page.content.general.headerImage}
      headerText={page.content.general.headerText}
      showQsm={Boolean(page.content.layout.qsm)}
      isTinted={Boolean(page.content.layout.backgroundTinted)}
      sailingAreas={sailingAreas}
      cruiseTypes={cruiseTypes}
      erpSailingAreas={erpSailingAreas}
    >
      <SeoHeaders seo={seo} pageTitle={pageTitle} pagePath={location.pathname} siteName={websiteName[theme]} />
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      {renderComponents.map((renderComponent, index) => {
        return (
          <renderComponent.component
            key={index}
            theme={theme}
            data={renderComponent.data}
            privacy={privacy}
            cmsProducts={cmsProducts}
          />
        );
      })}
    </Layout>
  );
};
export default Page;

const parseComponents = pageComponents => {
  const renderComponents = [];
  // Match CMS components with website components
  for (var pageComponent of pageComponents) {
    if (!componentRepository[pageComponent.templateName.toLowerCase()]) {
      continue;
    }

    renderComponents.push({
      component: componentRepository[pageComponent.templateName.toLowerCase()],
      data: pageComponent,
    });
  }

  return renderComponents;
};

const componentRepository = {
  "block-cruisetypes": pageBlockCruiseTypes,
  "block-cruiseinspirations": pageBlockCruiseInspirations,
  "block-header": pageBlockHeader,
  "block-2columntextwithtitle": pageBlock2columntextwithtitle,
  "block-3columnimagetext": pageBlock3columnimagetext,
  "block-textgallery": pageBlockTextgallery,
  "block-imagesidetext": pageBlockImagesidetext,
  "block-paragraphwithsidebar": pageBlockParagraphwithsidebar,
  "block-largeimagesidetext": pageBlockLargeimagesidetext,
  "block-textblockhighlight": pageBlockTextpageblockhighlight,
  "block-quote": pageBlockQuote,
  "block-dynamic": pageBlockDynamic,
  "block-contactform": pageBlockContact,
  "block-newsletter": pageBlockNewsletter,
  "block-brochure-aanvraag": pageBlockBrochure,
  "block-brochure-formulier": pageBlockBrochureForm,
  "block-advantages": pageBlockAdvantages,
  // Not released in Tide
  "block-2columntextwithsidetitle": pageBlock2columntextwithsidetitle,
  "block-imageswithhovercaption": pageBlockImageswithhovercaption,
};
