import React from "react";
import Newsletter from "../newsletter";

const PageBlockNewsletter = ({ data }) => {
  return (
    <section className="newsletter">
      <div className="newsletter__title">
        <h2>
          Val niet uit de boot, <br />
          blijf op de hoogte.
        </h2>
      </div>
      <Newsletter
        description={data.content.general.text}
        templateId={data.content.general.conformationEmailTemplate?.key}
        showInFooter={false}
      ></Newsletter>
    </section>
  );
};

export default PageBlockNewsletter;
