import React, { useEffect, useState, useCallback } from "react";
import { Link } from "gatsby";
import { apiKey, host } from "../../settings";
import { isValidEmail, isValidPhone } from "../../utils/validationUtils";

import SubmitButton from "../submitButton";
import { isEmpty } from "lodash";

const BrochureForm = ({ data, privacy }) => {
  const initialValues = {
    salutation: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    street: "",
    number: "",
    mailbox: "",
    postalCode: "",
    city: "",
    country: "",
    checkPrivacyPolicy: false,
    checkNewsletter: false,
  };

  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const isValid = Object.keys(errors).length === 0;

  const validateForm = useCallback(() => {
    const errors = {};

    if (values.salutation.length === 0) {
      setIsEnabled(true);
      errors.salutation = "Aanspreektitel *";
    }

    if (values.firstName.length === 0) {
      setIsEnabled(true);
      errors.firstName = "Voornaam *";
    }

    if (values.lastName.length === 0) {
      setIsEnabled(true);
      errors.lastName = "familienaam *";
    }

    if (values.street.length === 0) {
      setIsEnabled(true);
      errors.street = "Straat *";
    }

    if (values.number.length === 0) {
      setIsEnabled(true);
      errors.number = "Huisnummer *";
    }

    if (values.postalCode.length === 0) {
      setIsEnabled(true);
      errors.postalCode = "Postcode *";
    }

    if (values.city.length === 0) {
      setIsEnabled(true);
      errors.city = "Stad *";
    }

    if (values.country.length === 0) {
      setIsEnabled(true);
      errors.country = "Land *";
    }

    if (!values.checkPrivacyPolicy) {
      setIsEnabled(true);
      errors.checkPrivacyPolicy = "checkbox privacy policy *";
    }

    if (!isEmpty(values.phone)) {
      if (!isValidPhone(values.phone)) {
        setIsEnabled(true);
        errors.phone = "Telefoonnummer";
      }
    }

    if (!isValidEmail(values.email)) {
      setIsEnabled(true);
      errors.email = "Email *";
    }

    setErrors(errors);
  }, [
    values.checkPrivacyPolicy,
    values.city.length,
    values.country.length,
    values.email,
    values.firstName.length,
    values.lastName.length,
    values.number.length,
    values.phone,
    values.postalCode.length,
    values.salutation.length,
    values.street.length,
  ]);

  useEffect(() => {
    validateForm();
  }, [values, validateForm]);

  const requestBrochure = request => {
    return (async () => {
      setIsLoading(true);

      const url = `${host}/api/web/crmcontact`;

      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": apiKey,
        },
        body: JSON.stringify(request),
      }).then(res => {
        if (res.ok) {
          setIsSent(true);
          sendConfirmationMail(request);
        } else {
          setErrors({ fail: true });
        }
      });
      setIsEnabled(true);
      setIsLoading(false);
    })();
  };

  const sendConfirmationMail = async request => {
    const url = `${host}/api/web/mail`;
    const emailToSend = {
      firstName: request.firstName,
      lastName: request.lastName,
      emailAddress: request.email,
      phone: request.phone,
      emailTemplate: data.content.general.conformationEmailTemplate?.key,
      street: request.street,
      houseNumber: request.number,
      mailbox: request.box,
      postalCode: request.postalCode,
      city: request.city,
      country: request.country,
    };
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": apiKey,
      },
      body: JSON.stringify(emailToSend),
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setTouched(
      Object.keys(values).reduce((prev, cur) => {
        prev[cur] = true;
        return prev;
      }, {})
    );

    if (isValid) {
      const request = {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.salutation,
        email: values.email,
        phone: values.phone,
        street: values.street,
        number: values.number,
        box: values.mailbox,
        postalCode: values.postalCode,
        city: values.city,
        country: values.country,
        tags: [5],
      };
      if (values.checkNewsletter) {
        request.tags.push(3);
      }
      setIsEnabled(false);
      requestBrochure(request);
    }
  };

  const handleBlur = e => {
    setTouched(
      Object.assign({}, touched, {
        [e.target.name]: true,
      })
    );
  };

  const handleChange = e => {
    e.persist();
    if (e.target.type === "checkbox") {
      values[e.target.name] = e.target.checked;
      setValues(values);
      validateForm();
    } else {
      setValues(
        Object.assign({}, values, {
          [e.target.name]: e.target.value,
        })
      );
    }
  };

  return (
    <div className="form-wrapper">
      <h2>{data.content.general.title}</h2>
      <form className="form">
        <div className="form__row form__row--salutation">
          <div className="form__col">
            <label className={`form__label ${touched.salutation && errors.salutation ? "form__label--error" : ""}`}>
              Aanspreektitel *
            </label>
            <div className="radio-buttons">
              <div className="radio-button">
                <input id="sir" type="radio" name="salutation" value="0" onChange={handleChange} onBlur={handleBlur} />
                <label htmlFor="sir">Dhr</label>
              </div>
              <div className="radio-button">
                <input id="mrs" type="radio" name="salutation" value="1" onChange={handleChange} onBlur={handleBlur} />
                <label htmlFor="mrs">Mw</label>
              </div>
              <div className="radio-button">
                <input
                  id="different"
                  type="radio"
                  name="salutation"
                  value="2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="different">Anders</label>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__col form__col--sm-50 form__col--md-25">
            <label htmlFor="firstName">
              <span className={`form__label ${touched.firstName && errors.firstName ? "form__label--error" : ""}`}>
                Voornaam *
              </span>
            </label>
            <input
              id="firstName"
              className={`form__input ${touched.firstName && errors.firstName ? "form__input--error" : ""}`}
              type="text"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--sm-50 form__col--md-25">
            <label htmlFor="lastName">
              <span className={`form__label ${touched.lastName && errors.lastName ? "form__label--error" : ""}`}>
                Familienaam *
              </span>
            </label>
            <input
              id="lastName"
              className={`form__input ${touched.lastName && errors.lastName ? "form__input--error" : ""}`}
              type="text"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--sm-50 form__col--md-25">
            <label htmlFor="phone">
              <span className={`form__label ${touched.phone && errors.phone ? "form__label--error" : ""}`}>
                Telefoonnummer
              </span>
            </label>
            <input
              id="phone"
              className={`form__input ${touched.phone && errors.phone ? "form__input--error" : ""}`}
              type="text"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--sm-50 form__col--md-25">
            <label htmlFor="email">
              <span className={`form__label ${touched.email && errors.email ? "form__label--error" : ""}`}>
                E-mailadres *
              </span>
            </label>
            <input
              id="email"
              className={`form__input ${touched.email && errors.email ? "form__input--error" : ""}`}
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__col form__col--50 form__col--md-70">
            <label htmlFor="street">
              <span className={`form__label ${touched.street && errors.street ? "form__label--error" : ""}`}>
                Straat *
              </span>
            </label>
            <input
              id="street"
              className={`form__input ${touched.street && errors.street ? "form__input--error" : ""}`}
              type="text"
              name="street"
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--25 form__col--md-20">
            <label htmlFor="number">
              <span className={`form__label ${touched.number && errors.number ? "form__label--error" : ""}`}>
                Huisnummer *
              </span>
            </label>
            <input
              id="number"
              className={`form__input ${touched.number && errors.number ? "form__input--error" : ""}`}
              type="number"
              name="number"
              value={values.number}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col  form__col--25 form__col--md-10">
            <label htmlFor="mailbox">
              <span className="form__label">bus</span>
            </label>
            <input
              id="mailbox"
              className="form__input"
              type="text"
              name="mailbox"
              value={values.mailbox}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__col form__col--25">
            <label htmlFor="postalCode">
              <span className={`form__label ${touched.postalCode && errors.postalCode ? "form__label--error" : ""}`}>
                Postcode *
              </span>
            </label>
            <input
              id="postalCode"
              className={`form__input ${touched.postalCode && errors.postalCode ? "form__input--error" : ""}`}
              type="text"
              name="postalCode"
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--50">
            <label htmlFor="city">
              <span className={`form__label ${touched.city && errors.city ? "form__label--error" : ""}`}>Stad *</span>
            </label>
            <input
              id="city"
              className={`form__input ${touched.city && errors.city ? "form__input--error" : ""}`}
              type="text"
              name="city"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--25">
            <label htmlFor="country">
              <span className="form__label">Land *</span>
            </label>
            <input
              id="country"
              className="form__input"
              type="text"
              name="country"
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__col">
            <label className="checkbox">
              <input
                id="checkPrivacyPolicy"
                className={`checkbox__input ${
                  touched.checkPrivacyPolicy && errors.checkPrivacyPolicy ? "checkbox__input--error" : ""
                }`}
                type="checkbox"
                name="checkPrivacyPolicy"
                value={values.checkPrivacyPolicy}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="checkbox__label">
                Ik ga akkoord met de{" "}
                <a href={`/${privacy}` ?? "/"} target="_blank">
                  privacy policy *
                </a>
                .
              </span>
            </label>
          </div>
        </div>
        <div className="form__row">
          <div className="form__col">
            <label className="checkbox">
              <input
                id="checkNewsletter"
                className="checkbox__input"
                type="checkbox"
                name="checkNewsletter"
                value={values.checkNewsletter}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="checkbox__label">{data.content.general.subscribeToNewsLetter}</span>
            </label>
          </div>
        </div>

        {Boolean(Object.keys(touched).filter(key => Boolean(errors[key])).length) && (
          <div className="form__row form__row--alter">
            <div className="form__col">
              <div className="error">Volgende velden zijn niet correct ingevuld. Kijk deze even na:</div>
            </div>
            <div className="form__col form__col--errors">
              {Object.keys(touched)
                .filter(key => Boolean(errors[key]))
                .map((item, index) => (
                  <label className="errorlabel" key={index}>
                    {errors[item]}
                  </label>
                ))}
            </div>
          </div>
        )}
        {Boolean(isSent) && (
          <div className="form__row">
            <div className="form__col">
              <label className="successlabel">Uw aanvraag is verstuurd, bedankt!</label>
            </div>
          </div>
        )}
        {Boolean(errors.fail) && (
          <div className="form__row">
            <div className="form__col">
              <label className="error errorlabel">Verzoek niet verwerkt, probeer later opnieuw.</label>
            </div>
          </div>
        )}
        <div className="form__row">
          <SubmitButton
            text={data.content.general.buttonText}
            isLoading={isLoading}
            isEnabled={isEnabled}
            onClick={handleSubmit}
            isSecondary={false}
          />
        </div>
      </form>
    </div>
  );
};
export default BrochureForm;
