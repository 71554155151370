import React from "react";

export default ({ data }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  if (!data.content.general) {
    return null;
  }

  const blockdata = data.content.general;

  return (
    <section className="page-block-quote">
      <div className="page-block-quote__container">
        <hr />
        <div className="page-block-quote__row">
          <div className="page-block-quote__col">
            <span className="page-block-quote__phrase" dangerouslySetInnerHTML={{ __html: blockdata.text }} />
          </div>
          <div className="page-block-quote__col">
            <span className="page-block-quote__author-marks">~</span>
            <span className="page-block-quote__author" dangerouslySetInnerHTML={{ __html: blockdata.author }}></span>
          </div>
        </div>
        <hr />
      </div>
    </section>
  );
};
