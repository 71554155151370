import React from "react";
import InspirationCard from "./inspirationCard";

export default ({ data }) => {
  // Fast escape
  if (
    !data ||
    !data.content ||
    !data.content.general ||
    !data.content.general.cruiseInspiration1 ||
    !data.content.general.cruiseInspiration2 ||
    !data.content.general.cruiseInspiration3
  ) {
    return <></>;
  }

  const general = data.content.general;
  const cruiseInspirations = [general.cruiseInspiration1, general.cruiseInspiration2, general.cruiseInspiration3];

  return (
    <section className="inspiration">
      <div className="inspiration__title">
        <h2>Cruise-inspiratie</h2>
      </div>
      <div className="inspiration__content">
        {cruiseInspirations.map((inspiration, index) => (
          <div key={index} className="inspiration__item">
            <InspirationCard inspiration={inspiration} />
          </div>
        ))}
      </div>
    </section>
  );
};
