import React from "react";
import CruiseTypeCard from "./cruiseTypeCard";

export default ({ theme, data }) => {
  // Fast escape
  if (
    !data ||
    !data.content ||
    !data.content.general ||
    !data.content.general.cruisetype1 ||
    !data.content.general.cruisetype2 ||
    !data.content.general.cruisetype3
  ) {
    return <></>;
  }

  const general = data.content.general;
  const cruiseTypes = [general.cruisetype1, general.cruisetype2, general.cruisetype3];

  return (
    <section className="cruise-types">
      <div className="cruise-types__content">
        {cruiseTypes.map((cruiseType, index) => (
          <div key={index} className="cruise-types__item">
            <CruiseTypeCard theme={theme} cruiseType={cruiseType} />
          </div>
        ))}
      </div>
    </section>
  );
};
