import React from "react";
import { Link } from "gatsby";

export default ({ theme, data }) => {
  // Fast escape
  if (
    !data ||
    !data.content ||
    !data.content.general ||
    !data.content.advantages ||
    !data.content.advantages.advantage1 ||
    !data.content.advantages.advantage2 ||
    !data.content.advantages.advantage3 ||
    !data.content.advantages.advantage4 ||
    !data.content.advantages.advantage5
  ) {
    return <></>;
  }

  const advantages = [
    data.content.advantages.advantage1,
    data.content.advantages.advantage2,
    data.content.advantages.advantage3,
    data.content.advantages.advantage4,
    data.content.advantages.advantage5,
  ];

  return (
    <section className="cruises">
      <div className="cruises__title">
        <h2>{data.content.general.title}</h2>
      </div>
      <div className="cruises__content">
        {advantages.map((advantage, index) => (
          <div key={index} className="cruises__item">
            {/* For now we have decided (with Thalassa) to always show the cruise icon. Reason: the text can be changed in the CMS but this won't always match the icon anymore */}
            <img src={`/${theme}/svg/cruise-4.svg`} alt="cruise" className="cruises__item-icon" />
            <p>{advantage}</p>
          </div>
        ))}
      </div>
      <div className="cruises__cta">
        <Link to={`/${data.content.general.path}`}>Ontdek alle voordelen</Link>
      </div>
    </section>
  );
};
