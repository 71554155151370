import React from "react";

export default ({ data }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  if (!(data.content.general && data.content.layout)) {
    return null;
  }
  const blockdata = data.content.general;
  const layout = data.content.layout;

  let className = "page-block-2columntextwithsidetitle";

  if (layout.textPositionRight) {
    className += " page-block-2columntextwithsidetitle--right";
  }

  return (
    <section className={className}>
      <div className="page-block-2columntextwithsidetitle__container">
        <div className="page-block-2columntextwithsidetitle__row">
          <div className="page-block-2columntextwithsidetitle__header">
            <h2>{blockdata.title}</h2>
          </div>
          <div className="page-block-2columntextwithsidetitle__content">
            <div
              className="page-block-2columntextwithsidetitle__col"
              dangerouslySetInnerHTML={{ __html: blockdata.text1 }}
            />
            <div
              className="page-block-2columntextwithsidetitle__col"
              dangerouslySetInnerHTML={{ __html: blockdata.text2 }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
