import { Link } from "gatsby";
import React from "react";
import { addWidthParameter } from "../../../utils/addWidthParameter";

export default ({ inspiration }) => {
  // Fast escape
  if (!inspiration || !inspiration.content || !inspiration.content.general) {
    return <></>;
  }

  return (
    <div className="inspiration-card">
      <Link to={inspiration.content.general.path} className="link link--plain">
        <div className="inspiration-card__header">
          <img
            src={addWidthParameter(inspiration.content.general.image?.url, 714)}
            alt={inspiration.content.general.title}
          />
          <div>
            <div className="inspiration-card__tag">{inspiration.content.general.tag}</div>
          </div>
        </div>
      </Link>
      <div className="inspiration-card__title">
        <h5>
          <Link to={inspiration.content.general.path} className="link link--plain">
            {inspiration.content.general.title}
          </Link>
        </h5>
      </div>
      <div className="inspiration-card__description">{inspiration.content.general.description}</div>
    </div>
  );
};
