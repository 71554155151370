import { Link } from "gatsby";
import React, { useContext } from "react";
import { addWidthParameter } from "../../../utils/addWidthParameter";
import GlobalContext from "../../../contexts/global-provider";
import { getMonths } from "../../../utils/dateUtils";

const CruiseTypeCard = ({ theme, cruiseType }) => {
  const { setSearchSelection } = useContext(GlobalContext);
  // Fast escape
  if (!cruiseType || !cruiseType.content || !cruiseType.content.general || !cruiseType.content.usps) {
    return <></>;
  }

  const usps = [cruiseType.content.usps.usP1, cruiseType.content.usps.usP2, cruiseType.content.usps.usP3];

  // Actions
  const handleClick = cruiseType => {
    setSearchSelection({
      selectedCruiseTypes: [cruiseType],
      selectedSailingAreas: [],
      selectedDates: getMonths(),
      selectedDurations: [],
    });
  };

  return (
    <div className="cruise-type-card">
      {Boolean(cruiseType.content.general.image) && (
        <div className="cruise-type-card__header">
          <Link
            to={`/products?cruiseType=${cruiseType.itemId}`}
            onClick={() => handleClick(cruiseType)}
            className="link link--plain"
          >
            <img
              src={addWidthParameter(cruiseType.content.general.image?.url, 1136)}
              alt={cruiseType.content.general.title}
              className="cruise-type-card__header-media"
            />
          </Link>
        </div>
      )}

      <div className="cruise-type-card__body">
        <h3 className="cruise-type-card__title">
          <Link
            to={`/products?cruiseType=${cruiseType.itemId}`}
            onClick={() => handleClick(cruiseType)}
            className="link link--plain"
          >
            {cruiseType.content.general.title}
          </Link>
        </h3>
        <div
          className="cruise-type-card__description"
          dangerouslySetInnerHTML={{
            __html: cruiseType.content.general.description,
          }}
        />
        <div className="cruise-type-card__usps">
          {usps.map((x, i) => {
            if (!x?.content?.general) {
              return <div key={i}></div>;
            }

            return (
              <div key={i} className="cruise-type-card__usp">
                <img
                  src={`/${theme}/svg/${x.content.general.icon}.svg`}
                  alt={x.content.general.text}
                  className="cruise-type-card__usp-icon"
                />
                <p>{x.content.general.text}</p>
              </div>
            );
          })}
        </div>
        <div className="cruise-type-card__cta">
          <Link
            to={`/products?cruiseType=${cruiseType.itemId}`}
            onClick={() => handleClick(cruiseType)}
            className="cta"
          >
            Toon alle {cruiseType.content.general.title.toLowerCase()}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CruiseTypeCard;
