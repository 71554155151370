import React from "react";

export default ({ data }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general;
  const gallery = data.content.gallery;

  if (!(blockdata && gallery)) {
    return null;
  }
  return (
    <section className="page-block-imageswithhovercaption">
      <div className="page-block-imageswithhovercaption__container">
        <div className="page-block-imageswithhovercaption__row">
          <h2>{blockdata.title}</h2>
        </div>
        <div className="page-block-imageswithhovercaption__row">
          <div className="page-block-imageswithhovercaption__col">
            <figure className="page-block-imageswithhovercaption__figure">
              <img src={gallery.image1?.url} className="page-block-imageswithhovercaption__img" alt={blockdata.title} />
              <figcaption className="page-block-imageswithhovercaption__figcaption">
                <p>{blockdata.caption1}</p>
              </figcaption>
            </figure>
            <figure className="page-block-imageswithhovercaption__figure">
              <img src={gallery.image2?.url} className="page-block-imageswithhovercaption__img" alt={blockdata.title} />
              <figcaption className="page-block-imageswithhovercaption__figcaption">
                <p>{blockdata.caption2}</p>
              </figcaption>
            </figure>
            <figure className="page-block-imageswithhovercaption__figure">
              <img src={gallery.image3?.url} className="page-block-imageswithhovercaption__img" alt={blockdata.title} />
              <figcaption className="page-block-imageswithhovercaption__figcaption">
                <p>{blockdata.caption3}</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};
