import React from "react";
import { Link } from "gatsby";

const PageBlockBrochure = ({ data }) => {
  return (
    <section className="brochure">
      <div className="brochure__title">
        <h2>{data.content.general.title}</h2>
      </div>
      <div className="brochure__description">{data.content.general.intro}</div>

      <div className="brochure__actions">
        <Link to={data.content.general.buttonLink} title={data.content.general.buttonText} className="cta">
          {data.content.general.buttonText}
        </Link>
        <div className="brochure__actions-secondary">
          of
          <a
            href={data.content.general.pdfLink}
            title="bekijk de brochure online"
            className="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            bekijk de brochure online
          </a>
        </div>
      </div>
    </section>
  );
};

export default PageBlockBrochure;
